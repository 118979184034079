<!--
 * @Author: zl
 * @Date: 2022-12-12 17:06:31
 * @LastEditTime: 2022-12-13 15:23:17
 * @LastEditors: zl
 * @Description: 运维人员管理页面
-->
<template>
	<div class='OperationManagement-layout primary-layout'>
		<div class="primary-header text-right mb10">
			<div class="left-text">运维</div>
			<div class="btnGroups">
				<el-button size="small"
						   type="primary"
						   :loading="saveLoading"
						   @click="saveUserMaintenanceRole">保存</el-button>
			</div>
		</div>
		<div class="primary-content w-table-layout">
			<div class="w-table-content">
				<vxe-table align="center"
						   border
						   :data="tableObj.data"
						   height="100%"
						   highlight-hover-row
						   highlight-current-row
						   :loading="tableObj.loading"
						   ref="ywPersonTable"
						   show-overflow="tooltip"
						   show-header-overflow="tooltip"
						   :checkbox-config="{ trigger: 'row' }">
					<vxe-column type="checkbox"
								width="70"></vxe-column>
					<vxe-column field="sname"
								title="名称"
								width="200"></vxe-column>
					<vxe-column field="username"
								title="账号"
								width="200"></vxe-column>
					<vxe-column field="phone"
								title="电话号码"
								width="200"></vxe-column>
					<vxe-column field="tenantName"
								title="单位名称"
								header-align="center"
								align="left"
								min-width="500"></vxe-column>
					<vxe-column field="isFlag"
								title="账号状态"
								width="200">
						<template #default="{ row }">
							{{ row.isFlag ? '正常' : '已被删除或停用' }}
						</template>
					</vxe-column>
				</vxe-table>
			</div>
		</div>
	</div>
</template>

<script>
import { setItem } from '@/utils/storage'
import * as API from '@/api/home/homeIndex'

export default {
	props: {},
	components: {},
	data() {
		return {
			tableObj: {
				data: [],
				loading: false,
			},
			saveLoading: false,
		}
	},
	created() {
		setItem('isYw', true)
		// 获取运维人员配置
		this.getUserMaintenanceRole()
	},
	methods: {
		/******** 准备操作 ********/

		// 获取运维人员配置
		getUserMaintenanceRole() {
			this.tableObj.loading = true
			API.getUserMaintenanceRole({})
				.then((res) => {
					this.tableObj.loading = false
					this.tableObj.data = res.data
					res.data.map((x) => {
						if (x.isChecked == '1') {
							this.$refs.ywPersonTable.setCheckboxRow(x, true)
						}
					})
				})
				.catch(() => {
					this.tableObj.loading = false
				})
		},

		// 配置运维人员角色
		saveUserMaintenanceRole() {
			let arr = this.$refs.ywPersonTable.getCheckboxRecords()
			if (arr.length == 0) {
				this.$message.error('至少勾选一项！')
				return
			}
			let userIds = arr.map((x) => x.id)
			userIds = userIds.join(',')
			this.saveLoading = true
			API.saveUserMaintenanceRole({
				userIds,
			})
				.then((res) => {
					this.$message.success('保存完成！')
					this.getUserMaintenanceRole()
					this.saveLoading = false
				})
				.catch(() => {
					this.saveLoading = false
				})
		},
	},
	computed: {},
}
</script>

<style lang='less' scoped>
.OperationManagement-layout {
	height: 100%;
	padding: 20px !important;
}
</style>